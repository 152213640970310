<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <v-card
    outlined
    class="d-flex flex-column"
  >
    <v-card-title>
      <h5>{{ $t('column-settings.title') }}</h5>
      <v-spacer />
      <v-btn
        icon
        @click="hideColumnSettings"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-list class="dialog-content-overflow">
      <draggable
        v-model="tempColumnSettings"
        handle=".js-draggable"
        @change="saveColumnSettings"
      >
        <transition-group>
          <v-list-item
            v-for="col in tempColumnSettings"
            v-if="!col.hideDisplay"
            :key="col.value"
            :ripple="false"
            class="white"
            @click.stop="toggleColumnSetting(col.value)"
          >
            <v-list-item-action>
              <v-checkbox
                :input-value="col.visible"
                @click.stop="toggleColumnSetting(col.value)"
              />
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>
                {{ $t(col.text) }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon class="js-draggable lead-list__draggable">
              <v-icon>mdi-drag-horizontal-variant</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </transition-group>
      </draggable>
    </v-list>

    <v-alert
      v-if="noColumnsSelectedError"
      type="error"
      dense
      tile
      text
    >
      {{ $t('alerts.column-settings.error') }}
    </v-alert>
  </v-card>
</template>

<script>
import { DEFAULT_COLUMN_SETTINGS } from '../configs'
import brandingMixin from '@/mixins/branding'
export default {
  components: {
    draggable: () => import(/* webpackChunkName: "import" */ 'vuedraggable')
  },
  mixins: [brandingMixin],
  props: {
    columnSettings: {
      type: Array,
      default: () => DEFAULT_COLUMN_SETTINGS
    }
  },

  data () {
    return {
      tempColumnSettings: [...this.columnSettings],
      noColumnsSelectedError: false
    }
  },
  watch: {
    columnSettings: {
      handler (value) {
        this.tempColumnSettings = [...value]
      }
    }
  },

  methods: {
    hideColumnSettings () {
      this.$emit('close')
    },

    saveColumnSettings () {
      if (this.tempColumnSettings.some(col => col.visible)) {
        this.noColumnsSelectedError = false
        this.$emit('columnSettingsChanged', this.tempColumnSettings)
      } else {
        this.noColumnsSelectedError = true
      }
    },

    isConversion (value) {
      return ['conversionCountByType.VALUATION',
        'conversionCountByType.PLOT_VALUATION',
        'conversionCountByType.RETURN_CALL',
        'conversionCountByType.LIFE_ANNUITY',
        'conversionCountByType.PARTIAL_SALE',
        'conversionCountByType.PROPERTY_REQUEST',
        'conversions.download.postalShipping'].includes(value)
    },

    toggleColumnSetting (value) {
      this.tempColumnSettings = this.tempColumnSettings.map(col => ({
        ...col,
        ...(col.value === value && { visible: !col.visible }),
        ...(col.value === 'conversions' && this.isConversion(value) && { visible: true })
      }))
      if (!this.noColumnsSelectedError) {
        this.saveColumnSettings()
      }
    }
  }
}
</script>

<style>
.lead-list__draggable {
  cursor: move;
}
</style>
<style scoped>
.dialog-content-overflow{
  max-height: 100%;
  overflow: scroll
}
</style>
